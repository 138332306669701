<template>
    <div class="wrap">
        <el-page-header @back="$router.back()" :content="title" class="margin-bottom margin-top-xs"></el-page-header>
        <el-alert title="操作提示" type="success" class="margin-bottom">
            <div>
                1. 标识“*”的选项为必填项，其余为选填项。<br />
                2. 请按提示栏信息进行商品添加。
            </div>
        </el-alert>
        <div class="box">
            <div class="form_box">

                <el-form ref="form" :model="form" label-width="150px" v-loading="loading">
                    <div class="subItem">
                        <div class="itemize">基础信息</div>
                        <el-form-item label="制表人" :rules="rules.required">
                            <el-input v-model="form.completed_by"></el-input>
                        </el-form-item>
                        <el-form-item label="备注" :rules="rules.required">
                            <el-input type="textarea" v-model="form.remark"></el-input>
                        </el-form-item>
                        <el-form-item label="填表日期" :rules="rules.required">
                            <el-date-picker
                                    v-model="form.date"
                                    type="date"
                                    format="yyyy 年 MM 月 dd 日"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </div>
                    <div class="subItem">
                        <div class="itemize">工资总成本</div>
                        <el-form-item label="总经理工资" :rules="rules.required">
                            <el-input-number v-model="form.general_manager" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="店长工资" :rules="rules.required">
                            <el-input-number v-model="form.shopowner" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="销售顾问工资" :rules="rules.required">
                            <el-input-number v-model="form.sale" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="老师工资" :rules="rules.required">
                            <el-input-number v-model="form.teacher" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="后勤工资" :rules="rules.required">
                            <el-input-number v-model="form.logistics" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                    </div>
                    <div class="subItem">
                        <div class="itemize">产品总成本</div>
                        <el-form-item label="产品成本明细" :rules="rules.required">
                            <template>
                                <div class="specifications active" v-for="(list,index) in productCost" :key="list.id">
                                    <span style="margin-right: 10px">产品名:</span><el-input class="small" v-model="list.product_name"></el-input>
                                    <span style="margin-right: 10px">零售价:</span><el-input-number class="small" :precision="2" :step="1" :max="9999999" :min="0" v-model="list.retail_price"></el-input-number>
                                    <span style="margin-right: 10px">折扣 (百分比%):</span><el-input-number class="small" :precision="2" :step="1" :max="9999999" :min="0" v-model="list.discount"></el-input-number>
                                    <span style="margin-right: 10px">实际售价:</span><el-input-number class="small" :precision="2" :step="1" :max="9999999" :min="0" v-model="list.actual_price"></el-input-number>
                                    <el-button type="primary" plain size="small" @click="productCost.splice(index,1)">删除</el-button>
                                </div>
                            </template>
                            <el-form-item>
                                <el-button type="primary" plain size="small" @click="addProductCost">添加产品成本</el-button>
                            </el-form-item>
                        </el-form-item>

                    </div>
                    <div class="subItem">
                        <div class="itemize">其他成本</div>
                        <el-form-item label="房租" :rules="rules.required">
                            <el-input-number v-model="form.rent" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="水费" :rules="rules.required">
                            <el-input-number v-model="form.water" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="电费" :rules="rules.required">
                            <el-input-number v-model="form.electric" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="税费" :rules="rules.required">
                            <el-input-number v-model="form.taxation" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="电话费" :rules="rules.required">
                            <el-input-number v-model="form.phone_rate" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="办公费用" :rules="rules.required">
                            <el-input-number v-model="form.work_rate" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="耗材费" :rules="rules.required">
                            <el-input-number v-model="form.consumables" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="杂费" :rules="rules.required">
                            <el-input-number v-model="form.incidental" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="保值基金" :rules="rules.required">
                            <el-input-number v-model="form.fund" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                    </div>
                    <div class="subItem">
                        <div class="itemize">实际营业额</div>
                        <el-form-item label="实际营收额" :rules="rules.required">
                            <el-input-number v-model="form.actual_revenue" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="应收金额(现金业绩)" :rules="rules.required">
                            <el-input-number v-model="form.receivable" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="实耗占比" :rules="rules.required">
                            <el-input-number v-model="form.actual_consumption" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                            <span style="margin-left: 10px;color: red">单位为%,比如10%、20%、33.33%等</span>
                        </el-form-item>
                        <el-form-item label="实收金额(现金实收)" :rules="rules.required">
                            <el-input-number v-model="form.net_receipts" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                        <el-form-item label="未收金额(欠款)" :rules="rules.required">
                            <el-input-number v-model="form.uncollected" :precision="2" :min="0" :step="1" :max="9999999"></el-input-number>
                        </el-form-item>
                    </div>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()">保存</el-button>
                        <!-- <el-button type="primary" @click="onSubmit()">保存并继续添加</el-button> -->
                        <el-button @click="$router.back()">取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
    import rules from '../../../common/common.rules.js'
    export default {
        data() {
            return {
                myIndex:0,
                rules,
                classify: [],
                loading: false,
                changeTime:'',
                productCost:[],//产品总成本
                form: {
                    id: this.$route.params.id, // int 服务ID
                    date: '', // string 日期
                    actual_revenue: 0, // string 实际营收额
                    receivable: 0, // string 应收金额(现金业绩)
                    actual_consumption: 0, // string 实耗占比
                    net_receipts: 0, // string 实收金额(现金实收)
                    uncollected: 0, // string 未收金额(欠款)
                    general_manager: 0, // string  	总经理工资
                    shopowner: 0, // string 店长工资
                    sale: 0, // string  	销售顾问工资
                    teacher: 0, // string 老师工资
                    logistics: 0, // string 后勤工资
                    rent: 0, // string 房租
                    water: 0, // string 水费
                    electric: 0, // string 电费
                    taxation: 0, // string 税费
                    phone_rate: 0, // string 电话费
                    work_rate: 0, // string 办公
                    consumables: 0, // string 耗材费
                    incidental: 0, // string 杂费
                    fund: 0, // string 保值基金
                    product_cost_list: [], //  产品成本详情
                    remark:'',//备注
                    completed_by:'',//填表人

                }
            };
        },
        computed: {
            title() {
                if (this.form.id) {
                    return '编辑财务报表'
                } else {
                    return '新增财务报表'
                }
            }
        },
        mounted() {
            if (this.form.id) {
                this.$api.table.financeDetails({
                    id: this.form.id
                }).then(res => {
                    console.log(res)
                    this.form.date = res.data.date
                    this.form.actual_revenue = res.data.actual_revenue
                    this.form.receivable = res.data.receivable
                    this.form.actual_consumption= res.data.actual_consumption
                    this.form.net_receipts = res.data.net_receipts
                    this.form.uncollected = res.data.uncollected
                    this.form.general_manager = res.data.general_manager
                    this.form.shopowner = res.data.shopowner
                    this.form.sale = res.data.sale
                    this.form.teacher = res.data.teacher
                    this.form.logistics = res.data.logistics
                    this.form.rent = res.data.rent
                    this.form.water = res.data.water
                    this.form.electric = res.data.electric
                    this.form.taxation = res.data.taxation
                    this.form.phone_rate = res.data.phone_rate
                    this.form.work_rate = res.data.work_rate
                    this.form.consumables = res.data.consumables
                    this.form.incidental = res.data.incidental
                    this.form.fund = res.data.fund
                    this.form.remark = res.data.remark
                    this.form.completed_by = res.data.completed_by
                    res.data.product_cost_list.forEach(item=>{
                        this.productCost.push({
                            id: item.id,
                            product_name: item.product_name,//产品名
                            retail_price:item.retail_price,//零售价
                            discount:item.discount,//折扣 (百分比%)
                            actual_price:item.actual_price,//实际售价
                        })
                    })
                })
            }
        },
        methods: {
            onSubmit() {
                this.form.product_cost_list = this.productCost
                this.$refs.form.validate(valid => {
                    if (valid) {
                        const loading = this.$loading();
                        this.$api.table.saveFinance(this.form).then(res => {
                            loading.close();
                            if (res.code == 200) {
                                // 保存成功
                                this.$message.success(`保存成功`);
                                this.$router.back();
                            } else {
                                this.$message.error(res.msg);
                            }
                        }).catch(err => {
                            loading.close();
                            this.$alert(err);
                        });
                    } else {
                        this.$message.warning(`请检查输入错误`);
                        return false;
                    }
                });
            },
            //添加产品成本细节
            addProductCost(){
                this.productCost.push({
                    id: 'my-id-'+this.myIndex,
                    product_name: '',//产品名
                    retail_price:'',//零售价
                    discount:0,//折扣 (百分比%)
                    actual_price:0,//实际售价
                })
                this.myIndex++;
                console.log(this.productCost)
            }
        },
    };
</script>

<style scoped lang="scss">
    .form_box {

        .el-input,
        .el-textarea {
            width: 500px;
        }
    }
    .subItem{
        padding-left: 120px;
        padding-top: 60px;
        position: relative;
        .itemize{
            font-size: 16px;
            color: #333;
            padding-left: 20px;
            position: absolute;
            top: 0;
            left: 0;
        }
        .itemize:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            width: 10px;
            height: 10px;
            background-color: #409EFF;
        }
    }
    .specifications{
        padding: 0 20px 20px 20px;

    }
    .specifications  .el-input.small{
         width: 200px;
     }
    .specifications span{
        color:#606266;
        display: inline-block;
        position: relative;
        margin: 0 15px;
    }
</style>
